.roleIcon {
  margin-left: 100px;
  color: #0071CE;
  height: 24px;
  width: 20px;
}

.imgAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}
