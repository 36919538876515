.report-style-class {
    height: 600px;
    width: auto;
  }
  .PowerBIApp {
    margin: 20px;
    text-align: center;
  }
  .PowerBIApp-header{
     padding-top: 20x;
  }
