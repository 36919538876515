.rnl {
    flex-grow: 1;
    font-family:'Helvetica';
    font-size:18;
}
.menu{
    width: 30;
    height: 30;
}
.shape-1 {
    color:floralwhite;
}

.selectedDrawerItem{
    background-color: '#0071CE' !important;
    font-family: 'Helvetica Neue';
    font-size: '16px' !important;
    font-weight: 'bold' !important;
}