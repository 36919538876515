.rectangle1 {
    display: inline-block;
    width: 300px;
    height: 40px;
    background: #002E5C;
    margin-left:5px;
    margin-bottom:7px;
  }

  .rectangle2 {
    display: inline-block;
    width: 257px;
    height: 40px;
    background: #002E5C;
    margin-left:25px;
    margin-bottom:7px;

  }

  .rectangle3 {
    display: inline-block;
    width: 214px;
    height: 40px;
    background: #002E5C;
    margin-left:45px;
    margin-bottom:7px;
  }

  .rectangle4 {
    display: inline-block;
    width: 171px;
    height: 40px;
    background: #002E5C;
    margin-left:65px;
    margin-bottom:7px;
  }

  .rectangle5 {
    display: inline-block;
    width: 128px;
    height: 40px;
    background: #002E5C;
    margin-left:85px;
    margin-bottom:7px;
  }

  .rectangle6 {
    display: inline-block;
    width: 85px;
    height: 40px;
    background: #002E5C;
    margin-left:105px;
    margin-bottom:7px;
  }